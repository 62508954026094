import { createAPIEndpoint, ENDPOINTS, getUrl } from '../api'

export default function useMaternityServiceValidator(getFreshModelObject) {

  const exp = '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$';

    function isPostCodeValid([ S5, lastYearBorn ]) {
        if (!S5) {
            this.returnResult();
          return;
        }

        const regex = new RegExp(exp);
        if(!regex.test(S5)) {
          return;
        }  

        let url = getUrl(ENDPOINTS.getPostcode);

        fetch(url + S5 +"/"+ lastYearBorn)
          .then((response) => {
            if (!response.ok) {
              return null;
            }
            return response.json();
          })
          .then((data) => {
            const found = data != null;
            this.returnResult(found);
          });
      }

      function isPostcodeFormatValid(sender, option)
      {
        if (option.name === 'S5') {
          const postcode = option.value;
          if (!postcode)
            return;

            const regex = new RegExp(exp);
            if(!regex.test(postcode)) {
            option.error = "Please enter a valid postcode.";
          }  
        }
      }

    return {
        isPostCodeValid: isPostCodeValid,
        isPostcodeFormatValid: isPostcodeFormatValid
    }
}