/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useCallback, useState } from 'react'
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { createAPIEndpoint, ENDPOINTS } from '../../api'
import { json, useParams } from 'react-router-dom';
import { FunctionFactory } from "survey-core";
import useMaternityServiceValidator from '../../hooks/useMaternityServiceValidator'
import useRotationsValidator from '../../hooks/useRotationsValidator'
import { initializeTagManager } from "../tagManager";
import TagManager from "react-gtm-module";



StylesManager.applyTheme("defaultV2");

export default function RunSurvey() {
  const [ survey, setSurvey ] = useState(null);
  const [ model, setModel ] = useState(null);
  const [ savedData, setSavedData ] = useState(null);
  const { id } = useParams();
  const { isPostCodeValid, isPostcodeFormatValid  } = useMaternityServiceValidator();
  const { isTwoOptionsSelected } = useRotationsValidator();


  const onRedirect = (sender, options, currentSurvey) => {
    let data  = JSON.parse(localStorage.getItem('savedResult'))
    options.allow = !!options.url;
    var response = JSON.parse(data.response)
    
    if(response.S4a && response.S4a.length > 0 &&  response.S5){
      options.url = `${options.url}?userid=${data.repondentIdGuid}&passcode=${data.recordId}&rstatus=${data.status}`;
    }
    else {
      options.url = '';
    }
  }

  const onComplete = (sender, options, currentSurvey) => {
    var sumissionEvent = currentSurvey.id === "Survey010" 
                          ? "completed"
                          : "submitted";

    options.showDataSaving("Saving Survey Result....");
    sender.stopTimer();
    var timeSpent = sender.timeSpent;
     let result = sender.data;
     let completedBy = result.I2 ?? "";
     var data = {
      surveyId: currentSurvey.id,
      name: currentSurvey.surveyName,
      completedBy: completedBy,
      ipAddress: "",
      surveyResult: JSON.stringify(result),
      surveySchema: JSON.stringify(currentSurvey), 
      startTime: 0,
      endTime: timeSpent
    }

    TagManager.dataLayer({
      dataLayer: {
        event: sumissionEvent,
        page: "survey",
      },
    });

    return createAPIEndpoint(ENDPOINTS.saveResult).post(data)
  }

  const getDifference = (a, b) => 
  Object.fromEntries(Object.entries(b).filter(([key, val]) => key in a && a[key] !== val));

  const onValueChanging = (sender, options) => {

    if(survey.id=="Survey010" && options.name.startsWith("R"))
    {
      var oldValue = options.oldValue;
      var newValue = options.value;
      if(!oldValue)  return
      
      let valueArray=[];
        Object.values(options.value).forEach(val => {
        valueArray.push(val);
      });

      var duplicate = valueArray.filter((item, index) => valueArray.indexOf(item) !== index);

      Object.keys(options.oldValue).forEach(function(key){
        if (options.oldValue[key] === duplicate[0]) {
          delete options.value[key];
        }
      });
    }
  }

  
 

  const onMatrixCellValidate = (sender, options) => {
    debugger
    // console.log(sender);
    // console.log(options);
  }

  const onMatrixCellValueChanged = (sender, options) => {
    debugger
      console.log(sender);
      console.log(options);
  }

  const onCurrentPageChanged = (sender, options) => {
    fbq('track', 'PageView');
    TagManager.dataLayer({
      dataLayer: {
        event: options?.newCurrentPage?.name ?? "pageview",
        page: "survey",
      },
    });
  }




  const alertResults = useCallback((sender) => {
    const results = JSON.stringify(sender.data);
    alert(results);
  }, []);

  useEffect(() => {
    initializeTagManager();

    createAPIEndpoint(ENDPOINTS.runSurvey)
        .fetchById(id) 
        .then(res => {
          setSurvey(res.data);
          var modelData = new Model(res.data)
          setModel(modelData);
          var hasproperty = res.data.hasOwnProperty('customValidationFunctions');
          if(hasproperty && res.data.customValidationFunctions) {
            res.data.customValidationFunctions.forEach(value => {
              modelData.onValidateQuestion.add(eval(value));
              modelData.startTimer();
            });
          }


          modelData.onNavigateToUrl.add((sender, options) => onRedirect(sender, options));

          modelData.onComplete.add((sender, options) => 
            onComplete(sender, options, res.data)
            .then(onCompleteResult => {
              localStorage.setItem('savedResult', JSON.stringify(onCompleteResult.data))
                options.showDataSavingSuccess("Data Saved Successfully!");
              })
            .catch(err => { 
              console.log(err);
              options.showDataSavingError("Error when saving the survey result");
            })
        );

        })
        .catch(err => { console.log(err); })
        
       
        ! function(f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function() {
              n.callMethod ?
                  n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
      }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
      // eslint-disable-next-line no-undef
      fbq('init', '1086921515695060');
      // eslint-disable-next-line no-undef
      fbq('track', 'PageView');

  }, [])

  

  FunctionFactory.Instance.register("isPostCodeValid", isPostCodeValid, true);

  return ( 
    
    model != null
    ? <Survey model = {model} 
            onMatrixCellValueChanged = {onMatrixCellValueChanged}
            onMatrixCellValidate = {onMatrixCellValidate}
            onValueChanging = {onValueChanging}
            onCurrentPageChanged = {onCurrentPageChanged}

      />
    : null
  )
}

