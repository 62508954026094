import { AppBar, Button, Container, Toolbar, Box, Link, Typography } from '@mui/material'
import React from 'react'
import { Outlet, useNavigate } from 'react-router'
import useStateContext from '../hooks/useStateContext'

const rightLink = {
    fontSize: 16,
    color: 'common.white',
    ml: 3,
  };

export default function Layout() {
    const { resetContext } = useStateContext()
    const navigate = useNavigate()

    const logout = () => {
        resetContext()
        navigate("/")
    }

    return (
        <>
            <div>
            <AppBar position="fixed">
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ flex: 1 }} />
                <Link
                    variant="h6"
                    underline="none"
                    color="inherit"
                    href="/admin"
                    sx={{ fontSize: 24 }}
                >
                {'Dashboard'}
                </Link>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <Link
                        color="inherit"
                        variant="h6"
                        underline="none"
                        href=""
                        onClick={logout}
                        sx={rightLink}
                        >
                        {'Log Out'}
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
          <Toolbar />
        </div>
        <Container>
            <Outlet />
        </Container>
        </>
    )
}
