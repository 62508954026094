import axios from 'axios'
import authHeader from '../helper/authHeader'
import swal from 'sweetalert2'

export const BASE_URL = 'https://api.palstats.uk/';

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Your session is expired. You will be redirected to the login page.',
          }).then(function(result) {
            localStorage.clear(); 
            window.location = "/sign-in";
        });
    } else {
        return Promise.reject(error);
    }
});

axios.interceptors.request.use(
    config => {
        config.headers = authHeader();
        return config;
    },
    error => {
        Promise.reject(error)
});


export const ENDPOINTS = {
    participant: 'participant',
    question:'question',
    surveys:'survey/GetAll',
    survey:'survey/Get',
    runSurvey:'survey/Run',
    getPostcode:'Validation/GetPostCode',
    saveResult: 'survey/SaveSurveyResult',
    getSurveyResult: 'survey/GetSurveyResult',
    getAnswers : 'question/getanswers',
    login:"auth/login"
}

export const getUrl = endpoint =>
{
    return BASE_URL + 'api/' + endpoint + '/';
}

export const createAPIEndpoint = endpoint => {

    let url = BASE_URL + 'api/' + endpoint + '/';
    return {
        fetch: () => axios.get(url),
        fetchById: id => axios.get(url + id),
        post: newRecord => axios.post(url, newRecord),
        put: (id, updatedRecord) => axios.put(url + id, updatedRecord),
        delete: id => axios.delete(url + id),
    }
}