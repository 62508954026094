
export default function useRotationsValidator() {

    function isTwoOptionsSelected(_,  options) {
        if (options.name.startsWith("R")) {
          const selected = options.value;
          if (!selected)
            return;

          if(Object.keys(selected).length <= 1) {
            options.error = "Please select options for both Most Likely and Least Likely.";
          }
        }
      }

    return {
      isTwoOptionsSelected: isTwoOptionsSelected
    }
}