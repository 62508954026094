import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Authenticate from './components/Authenticate';
import Layout from './components/Layout';
import Login from './components/Login';
import Quiz from './components/Quiz';
import AdminArea from './modules/views/AdminArea';
import Home from './Home';
import Result from './components/Result';
import SignIn from './modules/views/SignIn';
import SignUp from './modules/views/SignUp';
import Survey from './modules/views/Survey';
import ForgotPassword from './modules/views/ForgotPassword';
import RunSurvey from './modules/views/RunSurvey';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/run-survey/:id" element={<RunSurvey />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<Authenticate />}>
          <Route path="/" element={<Layout />}>
            <Route path="/admin" element={<AdminArea />} />
            <Route path="/result" element={<Result />} />
            <Route path="/survey/:id" element={<Survey />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter >
  );
}

export default App;
