import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import { createAPIEndpoint, ENDPOINTS } from '../../api'
import { useParams } from 'react-router-dom';
import useStateContext from '../../hooks/useStateContext'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router'
import { DataGrid } from '@mui/x-data-grid'

export default function Survey() {
  const [ survey, setSurvey ] = useState([]);
  const [ surveyResult, setSurveyResult ] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.survey)
        .fetchById(id) 
        .then(res => {
          setSurvey(res.data)
        })
        .catch(err => { console.log(err); })

        createAPIEndpoint(ENDPOINTS.getSurveyResult)
        .fetchById(id) 
        .then(res => {
          setSurveyResult(res.data)
        })
        .catch(err => { console.log(err); })
    
  }, [])



  const handleClick = (id) => {
    navigate("/run-survey/" + id);
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { 
      field: 'createdDate', 
      headerName: 'Date', 
      width: 150,
      valueFormatter: params => 
      new Date(params?.value).toLocaleString()
    },
    //{ field: 'ipAddress', headerName: 'IP Address', width: 130 },
    { field: 'surveyResult', headerName: 'Reponse Data', width: 700 },
    // {
    //   field: 'ipAddress',
    //   headerName: 'IP Address',
    //   width: 90,
    // },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
  ];
  
  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];



  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
    >
 {survey != null && survey.active == true
  && (
    <>
        <Button
          onClick={()=>handleClick(survey.id)}
          sx={{
          border: '4px solid currentColor',
          borderRadius: 0,
          height: 'auto',
          py: 2,
          px: 5,
          }}
        >
        <Typography variant="h4" component="span">
            {survey.surveyName}
        </Typography>
        </Button>
        <Typography variant="subtitle1" sx={{ my: 3 }}>
            {survey.surveyDescription}
        </Typography>
        <Box
        component="img"
        src={survey.imagePath}
        alt="buoy"
        sx={{ width: 100 }}
        />
      </>
        )
       }
       <Typography variant="h4" marked="center" align="center" component="h2">
        Survey Responses
      </Typography>
      <div style={{ height: 400, margin__top:'15px', width: '100%' }}>
      <DataGrid
        rows={surveyResult}
        columns={columns} 
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
      />
    </div>
    </Container>
  );




  // return (
  //   <Container component="section" sx={{ mt: 8, mb: 4 }}>
  //      {context?.surveys?.map((survey) => (
  //       survey.id == id
  //       && (
  //         <>
  //             <Typography variant="h4" marked="center" align="center" component="h2">
  //                   {survey.surveyName}
  //             </Typography>
  //             <Typography variant="subtitle1" marked="center">
  //             We are here to help. Get in touch!
  //             </Typography>
  //             <Typography variant="h6" marked="center" align="center" component="h6">
  //                   {survey.surveyDescription}
  //             </Typography>
  //         </>
  //       )
  //      ))}
     
  //   </Container>
  // );
}
