
export default function authHeader() {
    const context = JSON.parse(localStorage.getItem('context'));

    if (context && context.principle) {
        return {
            'accept': '*',
            'Content-Type': 'application/*+json',
            "Authorization": 'Bearer ' + context.principle.token 
        };
    } else {
        return {
            'accept': '*',
            'Content-Type': 'application/*+json',
        }
    }
}