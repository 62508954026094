import React from 'react'
import { Navigate, Outlet } from 'react-router'
import useStateContext from '../hooks/useStateContext'

export default function Authenticate() {
    const { context } = useStateContext()
    return (
        context.principle == null
            ? <Navigate to="/sign-in" />
            : <Outlet />
    )
}
